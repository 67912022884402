<template>
  <div class="report-wrapper">
    <div
      class="pane mx-2 mt-2 mb-1"
      :class="dataFetched ? 'white' : ''"
    >
      <v-row
        class="heading-row align-center ma-0"
        :class="{ 'pb-2': $vuetify.breakpoint.smAndDown }"
      >
        <v-col
          class="col-lg-3 col-sm-7 col-7"
          :class="{'pa-0': $vuetify.breakpoint.smAndDown }"
        >
          <report-parameters-chooser />
        </v-col>
        <v-col
          v-if="Object.keys(getReportParams).length !== 0"
          class="vehicles-col col-lg-6"
        >
          <div class="mx-auto text-left" style="width: fit-content">
            <div>
              {{ getReportTypeTranslation }}
            </div>
            <div>
              {{
                $t('satellite-tracking/report.period_from_to')
                  .replace(':date_from', formatSqlDate(getReportParams.dateFrom))
                  .replace(':date_to', formatSqlDate(getReportParams.dateTo))
              }}
            </div>
            <div>
              {{ $t('satellite-tracking/report.report_for') }} {{ selectedVehiclesList }}
            </div>
          </div>
        </v-col>
        <v-col
          v-if="getSelectedVehicles.length"
          class="col-lg-3"
        >
          <v-row
            no-gutters
            class="d-flex flex-nowrap justify-center"
          >
            <v-btn
              v-if="dataFetched && 'chartConfig' in fetchedReport"
              small
              color="primary"
              class="mr-1"
              @click="showChart = !showChart"
            >
              {{ $t('satellite-tracking/report.show_chart') }}
              <v-icon
                dark
                right
                class="ml-1"
              >
                mdi-chart-line
              </v-icon>
            </v-btn>
            <download-report-button
              type="vehicle"
              route="vehicle-report/export"
              :data="getReportParams"
              :disabled="Object.keys(getReportParams).length === 0"
              :file-name="$t('satellite-tracking/report.report_for_vehicle') + selectedVehiclesList"
            />
          </v-row>
        </v-col>
      </v-row>
      <transition name="fade">
        <v-row
          v-if="!dataFetched"
          key="data-not-fetched"
          class="image-row justify-center py-10 ma-0"
        >
          <img
            :src="'/img/icons/report-vehicle-full.png'"
            alt="No pic"
          >
        </v-row>
      </transition>
    </div>
    <v-row
      v-if="!dataFetched"
      class="pictogram-row justify-center"
      no-gutters
    >
      <img
        :src="'/img/icons/table-pictogram.png'"
        alt="No pic"
        class="pictogram"
      >
    </v-row>
    <div
      v-if="dataFetched && showChart"
      class="mx-2 mb-2 pa-2 white"
      style="height: 50%;"
    >
      <report-chart-builder
        :fetched-report="fetchedReport"
        :report-params="getReportParams"
      />
    </div>
    <div
      v-if="dataFetched"
      class="pane white mx-2 mb-2"
      :class="{ 'h-50': showChart }"
      :style="{ overflowY: 'auto' }"
    >
      <div v-if="checkIfMultipleDevicesReportType(getReportParams.reportType)">
        <multiple-device-report-table
          :fetched-reports="fetchedReport"
          :vehicles="getSelectedVehicles"
          :report-type="getReportParams.reportType"
        />
      </div>
      <div v-else-if="getReportParams.reportType==='temperatureReport'">
        <temperature-report-table
          key="temperatureReport"
          :fetched-report="fetchedReport"
        />
      </div>
      <div v-else>
        <single-device-report-table
          :type="getReportParams.reportType"
          :fetched-report="fetchedReport"
        />
      </div>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="60"
      />
    </v-overlay>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { formatSqlDate } from '@/global/services/helpers/dates'
import ReportChartBuilder from '@/modules/base-module/reports/components/ReportChartBuilder'
import ReportParametersChooser from '@/modules/base-module/reports/report-by-vehicle/components/ReportParametersChooser'
import SingleDeviceReportTable from '@/modules/base-module/reports/report-by-vehicle/components/SingleDeviceReportTable'
import MultipleDeviceReportTable from '@/modules/base-module/reports/report-by-vehicle/components/MultipleDeviceReportTable'
import DownloadReportButton from '@/modules/base-module/reports/components/DownloadReportButton'
import TemperatureReportTable from '@/modules/base-module/reports/report-by-vehicle/components/TemperatureReportTable'

const {
  mapGetters,
  mapActions
} = createNamespacedHelpers('base/reportsByVehicle')

const {
  mapGetters: mapGettersConfig
} = createNamespacedHelpers('base/config')

export default {
  name: 'ReportByVehicle',

  components: {
    ReportParametersChooser,
    SingleDeviceReportTable,
    MultipleDeviceReportTable,
    DownloadReportButton,
    TemperatureReportTable,
    ReportChartBuilder
  },

  props: {},

  data () {
    return {
      showChart: false
    }
  },

  computed: {
    ...mapGetters([
      'fetchedReport',
      'getReportParams',
      'getSelectedVehicles',
      'loading',
      'getReportsMetaData'
    ]),

    ...mapGettersConfig(['companyScope']),

    dataFetched () {
      return 'data' in this.fetchedReport
    },

    getReportTypeTranslation () {
      if (this.getReportParams.reportType) {
        const reportData = this.getReportsMetaData.reportTypes.find(({ reportType }) => reportType === this.getReportParams.reportType)
        return reportData.reportName
      }
      return ''
    },

    selectedVehiclesList () {
      const reportType = this.getReportParams.reportType
      if (!reportType) return ''

      if (this.getSelectedVehicles.length > 5) {
        return this.getShortenedSelectedVehiclesLabel()
      }
      return this.mapSelectedVehiclesLabel()
    }
  },

  watch: {
    companyScope () {
      this.resetState()
      this.fetchReportsMetaData()
    }
  },

  beforeDestroy () {
    this.resetState()
  },

  mounted () {
    this.fetchReportsMetaData()
  },

  methods: {
    formatSqlDate,

    ...mapActions(['resetState', 'fetchReportsMetaData']),

    checkIfMultipleDevicesReportType (reportType) {
      const multipleDevicesReportTypes = ['reportByDay', 'idleVehicleOperation', 'businessPrivateDriveReport']
      return multipleDevicesReportTypes.includes(reportType)
    },

    mapSelectedVehiclesLabel () {
      return ': ' + this.getSelectedVehicles
        .map(vehicle => vehicle.registration + ' (' + vehicle.label + ')')
        .join(', ')
    },

    getShortenedSelectedVehiclesLabel () {
      return ': ' + this.getSelectedVehicles.length +
        ' ' + this.$t('satellite-tracking/report.vehicles')
    }
  }
}
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.h-50 {
  height: 50%;
}

.report-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 64px);
  overflow: hidden;
  border: 1px solid #ccc;

  > .pane {
    padding: 15px;
    overflow: hidden;

    &.white:first-child {
      flex-shrink: 0;
    }

    .heading-row {
      .vehicles-col {
        font-size: 13px;
        font-weight: 500;
        line-height: 1rem;
        letter-spacing: 0.0125em;
        font-family: "Roboto", sans-serif;
      }
    }

    .image-row {
      height: 90%;

      img {
        opacity: 0.75;
        height: 100%;
        width: auto;
      }
    }

    ~ .pane {
      border-top: 1px solid #ccc;
    }
  }

  .pictogram {
    opacity: 0.05;
    height: 100%;
    width: 100%;
  }
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th,
.v-data-footer {
  font-size: 0.75rem !important;
}

@media (max-width: 768px) {
  .v-slide-group__prev {
    display: none !important;
  }
  .v-data-table__wrapper {
    table {
      tbody {
        tr {
          td {
            border-radius: 1px;

            &:nth-of-type(odd):not(.summary-cell) {
              background-color: rgba(0, 0, 0, .025);
            }

            div {
              padding: 0 0.5rem;
            }
          }
        }
      }
    }
  }

  .report-wrapper {
    height: auto;
    width: 100%;

    .pane {
      overflow: scroll;

      .heading-row {
        flex-direction: column;

        .vehicles-col {
          font-size: 1rem;
          line-height: 1.5rem;
          letter-spacing: 0;
        }
      }

      .image-row {
        img {
          height: auto;
          width: 100%;
        }
      }
    }

    .pictogram-row {
      .pictogram {
        height: auto;
      }
    }
  }
}

@media (max-width: 480px) {
  .report-wrapper {
    height: auto;
  }
}
</style>
